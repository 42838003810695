"use client";

import { useState } from "react";
import useProvidersList from "@/hooks/useProvidersList";
import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";
import { Box, CircularProgress, Grid, Typography, useMediaQuery } from "@mui/material";
import GameMenu from "./GameMenu";
import DepositAndWithdrawButtonGroup from "./DepositAndWithdrawButtonGroup";
import MainMenu from "./MainMenu";

type Game = {
  category: string;
  name: string;
  code: string;
  status: boolean;
  display: boolean;
  image: string;
  image_newlobby: string;
  image_modify: string;
  is_lobby: boolean;
  games_lobby: any[];
};

const CATEGORY: {
  [key: string]: string;
} = {
  slot: "สล็อต",
  graph: "กราฟ",
  casino: "คาสิโนสด",
  card: "เกมไพ่",
  lottery: "หวย",
  sport: "กีฬา",
};

export default function ProvidersUFA() {
  const {
    groupedArray,
    isGameListLoading,
    theme,
    handleProviderClick,
    handleRightMenuClick,
    isLoggedIn,
    width,
    height,
    ItemImage,
    isActive,
    activePromotionPlatform,
  } = useProvidersList();
  const [category, setCategory] = useState(2)
  const [config] = useConfigStore((state) => [state.config], shallow);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const customAgentList = ['tiga98', 'funee8', 'ehub8', 'rvp8', 'v7heng', 'gax88', 'bcv789', 'kubwin']
  const isCustomAgentPreset = customAgentList.find(agent => agent === config?.agent_name)

  if (isGameListLoading) {
    return (
      <>
        <Grid sx={{ textAlign: "center" }}>
          <CircularProgress></CircularProgress>
        </Grid>
      </>
    );
  } else {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          {
            isLoggedIn && (
              <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{ pt: 3, pb: 0 }}
                >
                  <MainMenu />
                </Grid>
                {
                  isMobile && (
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{ pt: 3, pb: 0 }}
                    >
                      <DepositAndWithdrawButtonGroup />
                    </Grid>
                  )
                }
              </>
            )
          }
          {groupedArray
            .filter((item: any) => {
              const match = {
                0: 'slot',
                1: 'casino',
                2: 'sport',
                3: 'lottery'
              } as any

              return item.category === match[category]
            })
            .map((item: any, index) => (
              <Grid
                id={`category-${item.category}`}
                key={`category-${item.category}`}
                item
                xs={12}
                sx={{ pt: 3, pb: 0 }}
              >
                {/* {
                  category === 0 && (
                    <Typography
                      variant="h5"
                      sx={{ color: (theme) => theme.gradient[700] || "white" }}
                    >
                      {CATEGORY?.[item.category] || "~"}
                    </Typography>
                  )
                } */}
                <Box
                  mt={-0.5}
                  borderRadius={8}
                  width={"6px"}
                  height={"4px"}
                // bgcolor={category === 0 ? theme.palette.primary.main : 'none'}
                />
                <Grid
                  container
                  columns={{ xs: 9, sm: 9, md: 7 }}
                  spacing={1}
                  justifyContent={"space-start"}
                  alignItems={"center"}
                >
                  {item.data.map((game: any) => (
                    <Grid
                      key={game.code}
                      item
                      md={1}
                      sm={3}
                      xs={3}
                      position="relative"
                    >
                      <Box
                        onClick={
                          !isLoggedIn
                            ? () => {
                              handleRightMenuClick();
                            }
                            : undefined
                        }
                      >
                        <Box
                          style={{
                            textDecoration: "none",
                            color: theme.palette.primary.main,
                            textAlign: "center",
                          }}
                          onClick={() => {
                            handleProviderClick(
                              game?.games_lobby?.[0]?.id || "",
                              game?.code || "",
                              game?.category || "",
                              game?.games_lobby?.[0]?.code || "",
                              game?.games_lobby?.[0]?.is_iframe ?? true,
                              game?.code_betflik || ""
                            );
                          }}
                        >
                          <ItemImage
                            placeholder={"blur"}
                            blurDataURL="/assets/icons/category-casino.png"
                            src={
                              isCustomAgentPreset
                                ? game.name === 'Dream Gaming' && config?.agent_name !== 'bcv789'
                                  ? config?.agent_name !== 'kubwin'
                                    ? game?.image_newlobby
                                    : `https://images-platform.sgp1.cdn.digitaloceanspaces.com/gamepartners/custom/${config?.agent_name}/${game.name}.png` ||
                                    "/assets/icons/category-casino.png"
                                  : `https://images-platform.sgp1.cdn.digitaloceanspaces.com/gamepartners/custom/${config?.agent_name}/${game.name}.png` ||
                                  "/assets/icons/category-casino.png"
                                :
                                game?.image_newlobby ||
                                "/assets/icons/category-casino.png"
                            }
                            width={width}
                            height={height}
                            alt={game.name}
                            style={{
                              width: "100%",
                              height: "auto",
                              filter:
                                isLoggedIn &&
                                  isActive &&
                                  !activePromotionPlatform.includes(
                                    game?.code || ""
                                  )
                                  ? "grayscale(90%)"
                                  : "grayscale(0%)",
                            }}
                          />
                          <Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Box>
    );
  }
}
