"use client";

import useProvidersList from "@/hooks/useProvidersList";
import {
  Box,
  CircularProgress,
  Grid,
  useMediaQuery,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CardMedia,
  Skeleton
} from "@mui/material";
import LeaderBoardMenu from "./LeaderBoardMenu";
import useBoardWinner from "@/hooks/fetchers/useBoardWinner";
import { Icon } from "@iconify/react";
import { shallow } from "zustand/shallow";
import { useAuthStore } from "@/stores/authStore";
import useLaunch from "@/hooks/useLaunch";
import { isMobile as isMobileGame } from "react-device-detect";

interface Column {
  id: string
  label: string;
  width?: number;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

export default function LeaderBoard() {
  const { launchGame, loading: isGameLaunchLoading } = useLaunch();
  const { theme, activePromotionPlatform, isActive } = useProvidersList();
  const { isLoading, data, category, setCategory } = useBoardWinner()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoggedIn] = useAuthStore(
    (state) => [state.user!],
    shallow
  );

  const columns: readonly Column[] = category === 0
    ? [
      { id: "game", label: "เกม", minWidth: 100 },
      { id: "reward", label: "รางวัล", minWidth: 50 },
      { id: "multiplier", label: "ตัวคูณ", minWidth: 50, align: 'right' },
    ]
    : [
      { id: "game", label: "เกม", minWidth: 100 },
      { id: "bet", label: "เดิมพัน", minWidth: 50 },
      { id: "settle", label: "รางวัล", minWidth: 50, align: 'right' },
    ]

  const openModal = (game: any) => {
    // if (category !== 0) return
    if (!isLoggedIn) return
    if (isGameLaunchLoading) return
    if (isActive && !activePromotionPlatform.includes(game?.platform || "")) return

    launchGame(game?.game_id, isMobileGame, game?.is_iframe);
  }

  if (isLoading) {
    return (
      <>
        <Grid
          sx={{ textAlign: "center", pt: 3, pb: 3 }}
        >
          <LeaderBoardMenu category={category} setCategory={setCategory} />
        </Grid>
        <Grid sx={{ textAlign: "center" }}>
          {
            Array(10).fill(0).map((_val, index) => {
              return <Skeleton key={index} animation="wave" />
            })
          }
        </Grid>
      </>
    );
  } else {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ pt: 3, pb: 0 }}
          >
            <LeaderBoardMenu category={category} setCategory={setCategory} />
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} textAlign="left">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              minWidth: column.minWidth,
                              color: (theme) => theme.gradient[700], bgcolor: (theme) => theme.gradient[610],
                              ...(column.width && {
                                width: column.width
                              })
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data
                        .map((row: any) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                              sx={{
                                cursor: 'pointer',

                                // ...((category === 0) && {
                                //   cursor: 'pointer'
                                // }),
                              }}
                              onClick={() => openModal(row)}
                            >
                              {columns.map((column) => {
                                if (column.id === 'bet') {
                                  const username = row['username'];
                                  const bet = parseFloat(row['bet'])

                                  return (
                                    <TableCell key={column.id} align={column.align}>
                                      <Box
                                        display="flex"
                                        flexDirection="column"
                                      >
                                        <Typography variant="inherit" fontSize={'0.75rem'} color="white">
                                          {username}
                                        </Typography>
                                        <Typography variant="inherit" fontSize={'0.75rem'} color="#00be7a" fontWeight={'700'}>
                                          <span style={{ fontWeight: 400, color: 'rgba(255, 255, 255, 0.5)' }}>เดิมพัน: </span>
                                          {`${bet.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                  );
                                }

                                if (column.id === 'game') {
                                  const game = row['game'];
                                  const image = row['image'];
                                  const platform = row['platform'];

                                  return (
                                    <TableCell key={column.id} align={column.align}>
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <CardMedia
                                          component="img"
                                          image={image}
                                          title={game}
                                          style={{
                                            width: isMobile ? "28px" : "48px",
                                            height: isMobile ? "28px" : "48px",
                                            marginRight: "8px",
                                            borderRadius: '10%',
                                            filter: isActive && !activePromotionPlatform.includes(platform || "")
                                              ? "grayscale(90%)"
                                              : isGameLaunchLoading
                                                ? "grayscale(90%)"
                                                : "grayscale(0%)",
                                          }}
                                        />
                                        <Typography
                                          variant="inherit"
                                          sx={{ fontWeight: '600' }}
                                        >
                                          {game}
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                  );
                                }

                                if (column.id === 'settle') {
                                  const value = parseFloat(row[column.id]);
                                  const multiplier = parseFloat(row['multiplier'])

                                  return (
                                    <TableCell key={column.id} align={column.align}>
                                      <Box
                                        display="flex"
                                        flexDirection="column"
                                      >
                                        <Typography
                                          fontSize={'0.75rem'}
                                          variant="inherit"
                                          sx={{
                                            color: "#00be7a",
                                            fontWeight: '600'
                                          }}
                                        >
                                          {`${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                                        </Typography>
                                        <Typography fontSize={'0.7rem'} variant="inherit" color="rgba(255, 255, 255, 0.5)" fontWeight={'400'}>
                                          {`${multiplier.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}X`}
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                  );
                                }

                                if (column.id === 'turnover') {
                                  const username = row['username'];
                                  const turnover = parseFloat(row['turnover'])

                                  return (
                                    <TableCell key={column.id} align={column.align}>
                                      <Box
                                        display="flex"
                                        flexDirection="column"
                                      >
                                        <Typography variant="inherit" fontSize={'0.75rem'} color="white">
                                          {username}
                                        </Typography>
                                        <Typography variant="inherit" fontSize={'0.75rem'} color="#00be7a" fontWeight={'700'}>
                                          {`฿ ${turnover.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                  );
                                }

                                if (column.id === 'reward') {
                                  const username = row['username'];
                                  const settle = parseFloat(row['settle'])

                                  return (
                                    <TableCell key={column.id} align={column.align}>
                                      <Box
                                        display="flex"
                                        flexDirection="column"
                                      >
                                        <Typography variant="inherit" fontSize={'0.75rem'} color="white">
                                          {username}
                                        </Typography>
                                        <Typography variant="inherit" fontSize={'0.75rem'} color="#00be7a" fontWeight={'700'}>
                                          {`฿ ${settle.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                  );
                                }

                                if (column.id === 'id') {
                                  const value = row[column.id];
                                  return (
                                    <TableCell key={column.id} align={column.align}>
                                      {
                                        value < 4 ? (
                                          <img
                                            src={
                                              value === 1
                                                ? "https://images-platform.sgp1.cdn.digitaloceanspaces.com/general/c7e025a233753f3f900b4b53fe3c546cb0eee1e0.svg"
                                                : value === 2
                                                  ? "https://images-platform.sgp1.cdn.digitaloceanspaces.com/general/7d0b32d7954300abe034ded36de7adf790cb94a4.svg"
                                                  : "https://images-platform.sgp1.cdn.digitaloceanspaces.com/general/aaa315851b0bbefb202c6fec7e0a4b6ff35a7d5b.svg"
                                            }
                                            width={28}
                                            height={28}
                                          />
                                        ) : (
                                          <Typography
                                            variant="inherit"
                                            sx={{
                                              fontWeight: '600'
                                            }}
                                          >
                                            {`${value < 4 ? value : `${value}th`}`}
                                          </Typography>
                                        )
                                      }
                                    </TableCell>
                                  );
                                }

                                if (column.id === 'multiplier') {
                                  const value = parseFloat(row[column.id]);

                                  return (
                                    <TableCell key={column.id} align={column.align}>
                                      <Typography
                                        variant="inherit"
                                        sx={{
                                          color: "#00be7a",
                                          fontWeight: '600'
                                        }}
                                      >
                                        {`${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}X`}
                                      </Typography>
                                    </TableCell>
                                  );
                                }

                                const value = row[column.id];
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    <Typography
                                      variant="inherit"
                                      sx={{
                                        fontWeight: '600'
                                      }}
                                    >
                                      {`${value}`}
                                    </Typography>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Box >
    );
  }
}