import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, alpha, Tabs, Tab, useMediaQuery, Typography, Stack } from "@mui/material";
import { Icon } from "@iconify/react";
import { useRouter } from "next/navigation";
import { useAuthStore } from "@/stores/authStore";


const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.primary.main, 0.4)}`,
  borderRadius: 16,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.getContrastText(theme.palette.secondary.main),
  "&:hover": {
    color: theme.palette.primary.main,
    background: alpha(theme.palette.primary.main, 0.15),
  },
  ...((theme.name === "preset3" || theme.name === "preset6" || theme.name === "preset12" || theme.name === "preset13" || theme.name === "preset15") && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    "&:hover, &:active, &:focus": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      background: alpha(theme.palette.primary.main, 0.8),
    },
  }),
  ...((theme.name === "preset5") && {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    "&:hover, &:active, &:focus": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      background: alpha(theme.palette.primary.main, 0.8),
    },
  }),
  ...((theme.name === "preset17" || theme.name === "preset23" || theme.name === "preset27") && {
    background: theme.gradient[610],
    color: theme.palette.getContrastText(theme.palette.primary.main),
    "&:hover, &:active, &:focus": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      background: alpha(theme.palette.primary.main, 0.8),
    },
  }),
}));

type Menu = {
  key: string;
  label: string;
  icon?: string;
};

const menus: Menu[] = [
  {
    key: "multiply",
    label: "คูณโหด",
    icon: "",
  },
  {
    key: "bigwin",
    label: "แตกหนัก",
    icon: "",
  },
  // {
  //   key: "leader",
  //   label: "กระดานผู้นำ",
  //   icon: "",
  // },
  // {
  //   key: "recommend",
  //   label: "ล็อบบี้",
  //   icon: "emojione-v1:cherries",
  // },
  // {
  //   key: "slot",
  //   label: "สล็อต",
  //   icon: "emojione-v1:slot-machine",
  // },
  // {
  //   key: "casino",
  //   label: "คาสิโน",
  //   icon: "emojione-v1:joker",
  // },
  // {
  //   key: "sport",
  //   label: "กีฬา",
  //   icon: "emojione-v1:soccer-ball",
  // },
  // {
  //   key: "lottery",
  //   label: "ล็อตเตอรี่",
  //   icon: "emojione-v1:pool-8-ball",
  // },
  // {
  //   key: "fish",
  //   label: "ยิงปลา",
  //   icon: "logos:glitch-icon",
  // },
];

export default function LeaderBoardMenu(props: any) {
  const theme = useTheme();
  const [user] = useAuthStore((state) => [state.user]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const router = useRouter();

  const handleMenuClick = (_value: any, index: number) => {
    // console.log(index)

    props.setCategory(index)

    // router.push(`/?category=${key}`);
  };

  return (
    <Box sx={{
      flexGrow: 1,
    }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item
            sx={{
              display: 'flex',
              justifyContent: {
                xs: 'space-between',
                md: 'space-between',
                lg: 'space-between'
              },
            }}
          >
            <Tabs
              value={props.category}
              onChange={handleMenuClick}
              variant="scrollable"
              aria-label="game menu"
              indicatorColor={"none" as any}
              scrollButtons={false}
              // scrollButtons="auto"
              sx={{
                height: '54px',
                minHeight: '54px',
                // background: theme.gradient[100],
                // borderRadius: '2rem',
                alignItems: 'center'
              }}
            >
              {menus.map((menu, index) => (
                <Tab
                  // icon={
                  //   <Icon
                  //     id="icon"
                  //     icon={menu?.icon || ""}
                  //     width={24}
                  //     height={24}
                  //   />
                  // }
                  // iconPosition="start"
                  key={menu.key}
                  label={menu.label}
                  sx={{
                    color: props.category === index
                      ? theme.name === 'preset28'
                        ? '#fff'
                        : theme.name === 'preset16'
                          ? `${theme.gradient[100]} !important`
                          : (theme.gradient && theme.gradient[920])
                            ? `${theme.gradient[920]} !important`
                            : `${theme.palette.secondary.main} !important`
                      : (theme.gradient && theme.gradient[920])
                        ? theme.gradient[920]
                        : theme.palette.primary.main,
                    background: props.category === index
                      ? theme.name === 'preset16'
                        ? theme.palette.primary.main
                        : theme.gradient[100]
                      : theme.name === 'preset28'
                        ? '#010101'
                        : theme.name === 'preset16'
                          ? 'transparent'
                          : theme.gradient[400],
                    height: '44px',
                    minHeight: '44px',
                    borderRadius: '2rem',
                    border: props.category === index
                      ? theme.name === 'preset16'
                        ? 'initial'
                        : 'initial'
                      : theme.name === 'preset16'
                        ? `1px solid ${theme.palette.primary.main}`
                        : 'initial',
                    margin: index === 0
                      ? '0 0 0 0'
                      : menus.length === index + 1
                        ? '0 8px 0 8px'
                        : '0 0 0 8px',

                    // borderRadius: index === 0
                    //   ? '1rem 0 0 1rem'
                    //   : menus.length === index + 1
                    //     ? '0 1rem 1rem 0'
                    //     : 'initial',
                    // ...(isMobile && {
                    //   minWidth: "60px",
                    // }),
                  }}
                />
              ))}
            </Tabs>
            {/* {
              user && (
                <Stack
                  alignItems={"flex-end"}
                  justifyContent={"center"}
                  sx={{ mr: 1 }}
                  spacing={0}
                >
                  <Typography
                    variant="caption"
                    mb={-0.6}
                    color="white"
                  >
                    อันดับของคุณ
                  </Typography>
                  {
                    (user?.boardRank && user?.boardRank < 4) ? (
                      <img
                        src={
                          user?.boardRank === 1
                            ? "https://images-platform.sgp1.cdn.digitaloceanspaces.com/general/c7e025a233753f3f900b4b53fe3c546cb0eee1e0.svg"
                            : user?.boardRank === 2
                              ? "https://images-platform.sgp1.cdn.digitaloceanspaces.com/general/7d0b32d7954300abe034ded36de7adf790cb94a4.svg"
                              : "https://images-platform.sgp1.cdn.digitaloceanspaces.com/general/aaa315851b0bbefb202c6fec7e0a4b6ff35a7d5b.svg"
                        }
                        width={28}
                        height={28}
                      />
                    ) : (
                      <Typography
                        variant="subtitle2"
                        fontWeight={600}
                        color={"white"}
                      >
                        {user?.boardRank ? `${user?.boardRank}th` : '-'}
                      </Typography>
                    )
                  }
                </Stack>
              )
            } */}
          </Item>
        </Grid>
      </Grid>
    </Box>
  )
}