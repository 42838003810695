import { useState } from "react";
import { apiPath } from "@/configs/apiPath";
import { fetcherAuth } from "@/libs/utils";
import useSWR from "swr";

// type historyList = {
//     deposits: any;
//     withdraws: any;
// };

// type historyListReturn = {
//     data: historyList | null;
//     isLoading: boolean;
//     isError: any;
// };

export default function useBoardWinner(): any {
    const [category, setCategory] = useState(0)

    const { data, error } = useSWR<any>(
        category === 0 ? apiPath.board.winner : apiPath.board.bigwin,
        fetcherAuth,
        {
            refreshInterval: 2000,
        }
    );

    return {
        data: data?.data || null,
        isLoading: !error && !data,
        isError: error,
        category,
        setCategory
    };
}