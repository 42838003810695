"use client";

import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";
import gamelist from "@/hooks/fetchers/useProvider";
import useLaunch from "@/hooks/useLaunch";
import { useAppModalStore } from "@/stores/appModalStore";
import { useAuthModalStore } from "@/stores/authModalStore";
import { useAuthStore } from "@/stores/authStore";
import { useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import Image from "next/image";
import { useRouter, useSearchParams } from "next/navigation";
import * as React from "react";
import { isMobile } from "react-device-detect";
import { scroller } from "react-scroll";
import { shallow } from "zustand/shallow";
import usePlayingPopup from "./usePlayingPopup";
import useTransferWallet from "./useTransferWallet";

type Game = {
  category: string;
  name: string;
  code: string;
  status: boolean;
  display: boolean;
  image: string;
  image_newlobby: string;
  image_modify: string;
  is_lobby: boolean;
  games_lobby: any[];
  code_betflik: string;
};

type GroupedArrayItem = {
  category: string;
  data: Game[];
};

const CATEGORY: {
  [key: string]: string;
} = {
  slot: "สล็อต",
  graph: "กราฟ",
  casino: "คาสิโนสด",
  card: "เกมไพ่",
  lottery: "หวย",
  sport: "กีฬา",
};

const ItemImage = styled(Image)(({ theme }) => ({
  transition: "all 0.12s ease-in-out",
  "&:hover": {
    transform: "scale(1.08)",
    cursor: "pointer",
  },
  ...(theme.name === "preset13" && {
    backgroundColor: "#1b1c1d",
    borderRadius: "50px",
  }),
}));

export default function useProvidersList() {
  const theme = useTheme();
  const { handleTransfer, loading: transferLoading } = useTransferWallet();
  const { launchGame, loading: isGameLaunchLoading  } = useLaunch();
  const router = useRouter();
  const searchParams = useSearchParams();
  const category = searchParams.get("category");
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  let width: number, height: number;

  if (isMdUp) {
    width = 1667 / 8;
    height = 2417 / 8;
  } else if (isSmUp) {
    width = 1667 / 10;
    height = 2417 / 10;
  } else {
    width = 1667 / 14;
    height = 2417 / 14;
  }

  const {
    data: promotion,
    isLoading: isPromotionListLoading,
    isActive,
  } = usePromotionHistory();

  const activePromotionPlatform =
    promotion?.promotion?.platform?.split(",") || [];

  const [isLoggedIn, logout] = useAuthStore(
    (state) => [state.user!, state.logout],
    shallow
  );
  // const { data: gameList, isLoading: isGameListLoading } = gamelist();
  // const sortedKeys = Object.keys(CATEGORY);

  // const groupedData = gameList
  //   ? gameList
  //     .map((result: any) => {
  //       return result.code === 'KINGMAKER' ? { ...result, category: 'slot' } : result
  //     })
  //     .reduce((result: any, item: any) => {
  //       const category = item.category;
  //       if (!result[category]) {
  //         result[category] = [];
  //       }
  //       result[category].push(item);
  //       return result;
  //     }, {})
  //   : {};

  // const groupedArray: GroupedArrayItem[] = sortedKeys.reduce(
  //   (sortedArray: GroupedArrayItem[], key: string) => {
  //     let data = groupedData[key];
  //     if (data) {
  //       data = data.sort((a: Game, b: Game) => {
  //         const aIsInActivePromotionPlatform = activePromotionPlatform.includes(
  //           a.code
  //         );
  //         const bIsInActivePromotionPlatform = activePromotionPlatform.includes(
  //           b.code
  //         );
  //         if (aIsInActivePromotionPlatform && !bIsInActivePromotionPlatform) {
  //           return -1;
  //         }
  //         if (!aIsInActivePromotionPlatform && bIsInActivePromotionPlatform) {
  //           return 1;
  //         }
  //         return 0;
  //       });
  //       sortedArray.push({ category: key, data: data as Game[] });
  //     }
  //     return sortedArray;
  //   },
  //   []
  // );

  // React.useEffect(() => {
  //   if (category && groupedArray) {
  //     scroller.scrollTo(`category-${category}`, {
  //       duration: 1000,
  //       delay: 200,
  //       smooth: "easeInOutQuart",
  //       offset: -100,
  //     });
  //   }
  // }, [category, groupedArray]);

  const handleRightMenuClick = () => {
    setOpenLoginModal(true);
  };

  const handleProviderClick = (
    gameId: string,
    gameCode: string,
    category: string,
    platformCode: string,
    isIframe: boolean = true,
    code_betflik: string
  ) => {
    if (!isLoggedIn) return;

    if (["UFA"].includes(gameCode)) {
      if (isActive && ![...activePromotionPlatform].includes(gameCode)) return;
      launchGame(gameId, isMobile, isIframe);
      return;

      // setOpen(true);
      // setData("playingPopup");
      // handleTransfer("IN", "main", "ufa");
    } else if (code_betflik) {
      if (platformCode) {
        if (
          ["PG"].includes(code_betflik) &&
          [...activePromotionPlatform].includes(code_betflik)
        ) {
        } else {
          // setOpen(true);
          // setData("playingPopup");
          // handleTransfer("IN", "main", "betflix");
        }
      }
    }

    if (isActive && ![...activePromotionPlatform].includes(gameCode)) return;

    if (["PS", "CQ9", "FUNKY", "SP", "TTG", "PG"].includes(code_betflik)) {
      if (
        ["PG"].includes(code_betflik) &&
        [...activePromotionPlatform].includes(code_betflik)
      ) {
        router.push(`lobbies/${gameCode}`);
        return;
      } else {
        launchGame(gameId, isMobile, isIframe);
        return;
      }
    }

    if (["casino", "lottery", "sport"].includes(category)) {
      if (["WM"].includes(code_betflik)) {
        router.push(`lobbies/${gameCode}`);
        return;
      } else {
        launchGame(gameId, isMobile, isIframe);
        return;
      }
    }

    router.push(`lobbies/${gameCode}`);
  };

  const [setOpenLoginModal] = useAuthModalStore(
    (state) => [state.setOpenLoginModal],
    shallow
  );

  return {
    theme,
    groupedArray: [],
    isGameListLoading: false,
    isPromotionListLoading,
    handleProviderClick,
    handleRightMenuClick,
    isLoggedIn,
    width,
    height,
    ItemImage,
    isActive,
    activePromotionPlatform,
    handleTransfer,
    transferLoading,
    router,
    isGameLaunchLoading,
  };
}
